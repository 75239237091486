<template>
  <div>
    <div class="row mb-4">
      <div class="col-5">
        <div class="row mb-4">
          <div class="col-12">
            <div class="card">
              <div  class="card-header bg-primary text-white" style="border-radius: 5px"><i class="fas fa-user mr-2"></i><span>Leaderboard</span>
              </div>
              <div class="card-body">
                <table style="width: 100%; text-align: center;">
                  <thead>
                  <tr>
                    <td>Rank</td>
                    <td>Name</td>
                    <td>Steps</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(boardITem, index) in leaderBoardData.items" :key="index">
                    <td>
                      <label>{{index +1 }}</label>
                    </td>
                    <td>
                      <img v-if="boardITem.user.image.imageURL" :src="boardITem.user.image.imageURL" alt="img" style="width: 50px;border-radius: 50%;height: 50px;margin-right: 5px;">
                      <img v-else src="../../assets/img/user-default.png" alt="img" style="width: 50px">
                      <label>{{boardITem.user.userName}}</label>
                    </td>
                    <td>
                      <label>{{boardITem.steps}}</label>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'DashboardPage',
    data() {
      return {
        searchKey: '',
        updateArgs: [true, true, {duration: 1000}],
        currentIndex: 0,
        leaderBoardData: {
          items:{}
        }
      };
    },
    created() {
      const params = {
      periodType : 'MONTHLY',
      limit : 10,
      start : 0
      };

      this.getLeaderBoardData(params);
    },
    methods: {
      getLeaderBoardData(params){
        this.axios.get('user-action/leaderboard', {params})
            .then(response => {
              if (response.data.data && response.data.data) {
                this.leaderBoardData = response.data.data;
              }
            })
            .catch(error => {
              if (error.response && error.response.data.status === 400) {
                this.$toastr.error(error.response.data.message);
              }
            });
      },
    },
    components: {

    }
  };
</script>

<style scoped>

</style>